/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import IllustrationC from "../../images/illustrations/hero-landing.jpg"
import { Link } from "gatsby"

export default function Hero(offset) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
        <div
          className="hero image-as-background"
          style={{
            backgroundImage: `url(${IllustrationC})`,
          }}
        >
          <div className="relative overflow-hidden hero-gradient pt-12 pb-12">
            <div className="max-w-screen-xl mx-auto p-6 md:pt-12 sm:p-8">
              <div className="flex justify-center">
                <div className="mt-4">

                  <h2
                    style={{ fontSize: 24, color: "#ffffff"}}
                    className=" text-center pb-12 tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                  >
                    LIVE THE LIFE YOU DESIRE BY
                  </h2>
                  <h1 style={{color: "#ffffff"}} className="huge-font text-white text-center tracking-tight leading-8 sm:leading-none font-bold">
                    BEING
                  </h1>
                  <h2
                    style={{ color: "#ffcc00"}}
                    className="text-center tracking-tight leading-8 sm:leading-none font-bold med-font text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                  >
                    THE BEST OF YOURSELF
                  </h2>

                  <p className="text-center	mt-3 sm:mt-5 text-xs sm:text-md md:text-lg font-bold" style={{ maxWidth: 800, fontSize: 22, color: "#ffffff", }}>
                    Through our coaches, expands your awareness, GET honest feedback, SET your actions, GO for your success.
                  </p>
                  <div className="text-center	mt-6 sm:mt-8 sm:flex sm:justify-center lg:justify-center">

                    <div className="">
                    <Link
                      to={"/how-it-works-individuals"}
                      className="mr-8 cursor-pointer shadow bg-zone-cta focus:shadow-outline focus:outline-none text-white font-bold py-3 px-6 rounded"
                    >
                      Explore
                    </Link>

                     <Link
                      to={"/pricing"}
                      className="cursor-pointer shadow bg-zone-cta focus:shadow-outline focus:outline-none text-white font-bold py-3 px-6 rounded"
                    >
                      Get Started
                    </Link>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      
  )
}
