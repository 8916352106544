/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "styled-components"
import Illustration from "../../images/illustrations/one-on-one_new.png"
import { Link } from "gatsby"

const Header = styled.div`
  text-align: center;
  margin-bottom: 2em;
  display: flex;
  justify-content: center;
`

const OneOnOne = ({ offset }) => (
  <div>
    <div className="relative overflow-hidden">
      <div className="max-w-screen-xl mx-auto p-6 md:pt-6 sm:p-8">
        <Header>
          <div style={{ maxWidth: 800 }}>
            <h2
              style={{
                fontWeight: 800,
              fontSize: 28,
              }}
              className="tracking-tight font-bold sm:leading-none text-2xl sm:text-lg md:text-4xl mb-2"
            >
              A CONVERSATION
            </h2>
            <div className="para">
            that ignites and initiates you with
            </div>
          </div>
        </Header>
        <div className="m-4">
          <img
            className="md:mx-auto h-auto"
            src={Illustration}
            alt="Integrations"
            style={{
                width: 800,
              }}
          />
        </div>
                <div className="text-center mt-6 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                    <Link
                      target="_blank"
                      to={"https://form.typeform.com/to/DQ3mnyjA"}
                      className="cursor-pointer shadow bg-zone-cta focus:shadow-outline focus:outline-none text-white font-bold py-3 px-6 rounded"
                    >
                      Get Free Trial Session
                    </Link>
                  </div>
      </div>
    </div>
  </div>
)

export default OneOnOne
