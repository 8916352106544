/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"

import Coachben from "../../images/illustrations/beinganddoing.png"
import styled from "styled-components"
import { Link } from "gatsby"

const Header = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const ListItem = styled.li`
  padding: 4px;
  font-size: 18px;
  font-weight: 300;
  width: 25rem;
  display: flex;
  align-items: center;
  @media (min-width: 320px) and (max-width: 480px) {
    width: inherit;
  }
`

export default class Schedule extends React.Component {
  state = {
    name: "",
    email: "",
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })

    this.setState({
      mailToString:
        "/schedule-demo?name=" + this.state.name + "&email=" + this.state.email,
    })
  }

  render() {
    return (
      <div>
        <div className="relative overflow-hidden">
          <div className="mx-auto p-6 md:pt-12 sm:p-8">          
            <div className="md:flex p-2 justify-center">
            <div className="ml-4 mr-4 flex flex-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 ">
                <img
                    style={{ maxWidth: 600}}
                    className="w-auto h-auto"
                    src={Coachben}
                    alt="Coaching Benefits"
                />
            </div>
            <div className="ml-4 mr-4 flex flex-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 ">
            <div className="" >
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 28,
                      }}
                      className=" mt-4 uppercase tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Every Individual has 
                    </h2>
                    <h2 
                     style={{
                          fontWeight: 800,
                          fontSize: 36,
                          color: "#cc3366",
                        }}
                    className="uppercase tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl mb-2">
                      full potential
                    </h2>

                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="mt-1 para"
                    >
                      
                      BeingZone Coaches works on connecting your DOING with BEING. We investigate, understand, and redefine the beliefs, values, assumptions, strengths, and perspectives.

                    </p>
                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="mt-3 para"
                    >
                      We analyze your actions by breaking them down to see "Why are you doing the way you are doing?" and rebuild it with awareness and accountability step by step.
                    </p>
                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="mt-3 para"
                    >
                      We bring your DOING(behavior) closer to BEING(you), where you feel fully empowered by your decisions, actions, and expressions.
                    </p>
                    <p
                      style={{
                        fontSize: 24, fontWeight: 800,
                      }}
                      className="mt-3 uppercase"
                    >                      
                      You feel complete. ALIVE inside!
                    </p>
                </div>
            </div>
              
            </div>

          </div>
        </div>
      </div>
    )
  }
}
