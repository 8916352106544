/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import styled from "styled-components"

const Header = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export default class Schedule extends React.Component {
  state = {
    name: "",
    email: "",
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })

    this.setState({
      mailToString:
        "/schedule-demo?name=" + this.state.name + "&email=" + this.state.email,
    })
  }

  render() {
    return (
      <div>
        <div className="relative overflow-hidden">
          <div className="mx-auto p-6 md:pt-12 sm:p-8">
          <Header>
          <div>

            <h2
              style={{
                fontWeight: 800,
                fontSize: 28,
              }}
              className="tracking-tight font-bold sm:leading-none text-2xl sm:text-lg md:text-4xl mb-2"
            >
              GET YOUR PUSH
            </h2>
      
            <h2
              style={{
                fontWeight: 800,
                fontSize: 36,
                color: "#cc3366",
              }}
              className="tracking-tight font-bold sm:leading-none text-2xl sm:text-lg md:text-4xl"
            >
              COACHING TRANSFORMS YOU
            </h2>
            <p
              style={{
                fontWeight: 600,
                fontSize: 20,
              }}
              className="mt-2 mb-8 text-xs sm:text-sm md:text-md para"
            >
              Get honest feedback, clarity and confidence
            </p>
            </div>
            </Header>
            <div className="md:flex p-4 justify-center">
              <div className="ml-8 mr-8 flex flex-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 ">
               <div className="" >
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 20,
                      }}
                      className=" mt-4 uppercase tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Communication 
                    </h2>

                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="mt-1 para"
                    >
                      
                  Enable you to express clearly, communicate authentically, present yourself effectively.

                    </p>
                </div>
                 <div className="mt-6">
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 20,
                      }}
                      className=" mt-4 uppercase tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Relationship 
                    </h2>

                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="mt-1 para"
                    >
                      Building trust, being supportive, navigating critical events, dealing with, and moving on.
                    </p>
                </div>
                 <div className="mt-6">
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 20,
                      }}
                      className=" mt-4 uppercase tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Time-Management
                    </h2>

                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="mt-1 para"
                    >
                      Define goal clarity, conscious plan on time and energy, and increase focus and accountability.
                    </p>
                </div>
              </div>

              <div className="ml-8 mr-8 flex flex-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 ">
                <div className="">
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 20,
                      }}
                      className=" mt-4 uppercase tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Career Growth 
                    </h2>

                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="mt-1 para"
                    >
                      
                      What next? A clear path, dealing with change and transitions, refining thoughts to steer.

                    </p>
                </div>
                 <div className="mt-6">
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 20,
                      }}
                      className=" mt-4 uppercase tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Leadership
                    </h2>

                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="mt-1 para"
                    >
                      Work on expectations, confidence, and collaboration. To manage self and empower people around.
                    </p>
                </div>
                 <div className="mt-6">
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 20,
                      }}
                      className=" mt-4 uppercase tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Well-being
                    </h2>

                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="mt-1 para"
                    >
                      Build routines, break patterns, feel your best to perform. Learn to handle emotions and find meaning in life.
                    </p>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
}
