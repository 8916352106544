import React, { useEffect } from "react"
import Layout from "../components/layout"
import Hero from "../components/landing/hero_new"
import QuoteBlock from "../components/landing/quote"
import Areyoubeing from "../components/landing/beinganddoing"
import Part2 from "../components/landing/part2"
import OneOnOne from "../components/landing/one-on-one"
import ColorBlock from "../components/landing/color-block"
import Coachingfor from "../components/landing/coachingfor"
import Stats from "../components/landing/stats"

import styled from "styled-components"
import firebase from "gatsby-plugin-firebase"

const Header = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
`
const IndexPage = () => {
  useEffect(() => {
    console.log("🔥 Initializing Firebase Analytics & Performance 🔥")
    // firebase.analytics()
    // firebase.performance()
  }, [])

  return (
    <Layout>
      <Hero />
      <QuoteBlock />
      <OneOnOne />
      <ColorBlock />
      <Coachingfor />
      <Stats />
    </Layout>
  )
}

export default IndexPage
