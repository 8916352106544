/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "styled-components"
import backgroundImage from "../../images/illustrations/stats-background.jpg"

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  color: white;
`

const features = [
  {
    heading: "120",
    subheading: "Increased Performance",
    badge: "#ffcc66",
  },
  {
    heading: "97",
    subheading: "Increased level of engagement",
    badge: "#5b5bab",
  },
  {
    heading: "92",
    subheading: "Conquer Desired Goal",
    badge: "#d45673",
  },
  {
    heading: "89",
    subheading: "Increase Emotional Intelligence",
    badge: "#cc6600",
  },
]

const renderFeature = () => {
  let featuresData = []
  features.forEach((feature, index) => {
    featuresData.push(
      <div
        key={index}
        className="flex flex-col text-center w-full md:w-1/4 lg:w-1/4 xl:w-1/4 mb-4"
      >
        <h1 className="text-white  ml-8 mr-8" style={{ fontSize: 42 }}>
          {feature.heading}
          <sup style={{ fontSize: 18 }}>%</sup>
        </h1>
        <div className="self-center">
          <p
            style={{ width: 128 }}
            className="para text-white  ml-8 mr-8 text-xs sm:text-sm md:text-md"
          >
            {feature.subheading}
          </p>
        </div>

        <div className="self-center mt-2">
          <div
            style={{
              width: "72px",
              height: "8px",
              borderRadius: "12px",
              backgroundColor: `${feature.badge}`,
            }}
          ></div>
        </div>
      </div>
    )
  })
  return featuresData
}

const Stats = ({ offset }) => (
  <div
  className="hero image-as-background" 
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${backgroundImage})`,
      }}
   
  >
    <div className="relative overflow-hidden">
      <div className="max-w-screen-xl mx-auto p-6 md:pt-12 sm:p-8">
        <Header>
          <div style={{ fontSize: 20, }}>
            Coaching initiates and ignites the mind
          </div>
          <div style={{ fontSize: 20, textAlign: "right",  }}>
            when we <span style={{ fontWeight: 800 }}>transform</span>
            <br />
            we change everything around us
          </div>
        </Header>
        <div className="flex flex-wrap">{renderFeature()}</div>
      </div>
    </div>
  </div>
)

export default Stats
