/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "styled-components"
import icon_actionplan from "../../images/icons/icon_actionplan.png"
import icon_explore from "../../images/icons/icon_explore.png"
import icon_goal from "../../images/icons/icon_goal.png"
import icon_reflection from "../../images/icons/icon_reflection.png"

const Header = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const features = [
  {
    heading: "Personal Development",
    subheading: "Coaching helps build your routine and recognize your patterns to handle your emotions and actions.",
    icon: icon_goal,
    note: "Assessment, clarity and Reality",
  },
  {
    heading: "Professional Growth",
    subheading: "Coach navigates you and prepares you to face your challenges with your strengths for your career growth.",
    icon: icon_explore,
    note: "Beliefs, Values, Attitudes and Perspective",
  },
  {
    heading: "Potential Driven",
    subheading: "Coaches empower and enable you to perceive your potential to be the person you want to be.",
    icon: icon_actionplan,
    note: "Options and Strategy",
  },
  {
    heading: "Action and Impact",
    subheading: "Coaches partner with you to plan for your actions and help you to see the impact in 360, especially your blind spots.",
    icon: icon_reflection,
    note: "Accountability and Follow up",
  },
]

const renderFeature = () => {
  let featuresData = []
  features.forEach((feature, index) => {
    featuresData.push(
      <div
        key={index}
        className="flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 mb-12"
      >
        <h1
          className="ml-4 mr-8 uppercase"
          style={{ fontSize: 17 }}
        >
          {feature.heading}
        </h1>
        <div className="ml-10 mt-2">
          <div
            className="bg-yellow-400 flex justify-center mt-2 mb-2"
            style={{
              width: "56px",
              height: "56px",
              borderRadius: "28px",
            }}
          >
            <img
              style={{
                width: "36px",
                height: "auto",
              }}
              className="self-center"
              src={feature.icon}
              alt="illustrations"
            />
          </div>
        </div>

        
        <div className="self-center">
          <p
            style={{fontSize: 18, textAlign: "left", fontColor: "black" }}
            className="ml-2 mr-6 text-xs sm:text-sm md:text-md para"
          >
            {feature.subheading}
          </p>
          
        </div>
      </div>
    )
  })
  return featuresData
}

const Reflaction = ({ offset }) => (
  <div>
    <div className="relative overflow-hidden">
      <div className="max-w-screen-xl mx-auto p-6 md:pt-12 sm:p-8">
        <Header>
          {/*  */}
     
          <h2
            style={{
                fontWeight: 800,
              fontSize: 28,
              }}
            className="uppercase text-center tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl mb-2"
          >
            CONNECTING YOUR GOAL WITH THE PROCESS
          </h2>
          <h2 
           style={{
                fontWeight: 800,
                fontSize: 36,
                color: "#cc3366",
              }}
          className="uppercase text-center tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl mb-2">
            For Individuals and Teams
          </h2>

          <p className=" text-center font-bold para">
            From personal growth to organizational transformation 
          </p>
        </Header>
        <div className="mt-8 flex flex-wrap">{renderFeature()}</div>
      </div>
    </div>
  </div>
)

export default Reflaction
