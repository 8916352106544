/** @jsx jsx */
import { jsx } from "theme-ui"

import dashboardIllust from "../../images/illustrations/thought-confident.png"
import styled from "styled-components"

const ColorBlock = ({ offset }) => (
  <div style={{ backgroundColor: "#000099" }}>
    <div className="relative overflow-hidden">
      <div className="mx-auto p-6 md:pt-12 sm:p-8">
        <div className="md:flex p-3 justify-center ">
          <div className="flex flex-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 p-4">
            <h1 style={{ marginBottom: 0 }} className="mt-2 mb-2 text-white">
              What is Coaching?
            </h1>
            <p className="mt-4 text-white para pr-2"  >
              Coaching is a space to identify, understand, and recognize the strength within the self. Coaching is about bringing awareness and feeling positive to nurture your mind to be more confident, conscious, and consistent.
            </p>
            <p className="mt-4 text-white para pr-2"  >
              Coaches become your partner in your lifes journey for your goals & purpose. Some people have personal or professional goals; some focus on communication or leadership. It is your space, and you can focus on any aspiration to bring into motion.
            </p>
            <p className="mt-4 text-white para pr-2"  >
              Being Zone Coaches are here to partner with you, and they will enable, embody, and empower you throughout your journey. 
            </p>
          </div>
          <div className="self-center hidden md:block flex flex-col md:w-1/2 lg:w-1/2 xl:w-1/2 ">
            <img
              className="w-auto h-auto"
              src={dashboardIllust}
              alt="illustration"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default ColorBlock
